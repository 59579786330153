import React from 'react'
import { Container, Link, Typography } from '@mui/material'
import Layout from '../components/layout'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const InformationPage = () => {
    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const title = `お知らせ | ${siteTitle}`
    const description = `お知らせ`
    return (
        <Layout title={title} description={description}>
            <Container maxWidth="md" sx={{ pt: { xs: 3, sm: 6 } }}>
                <Typography gutterBottom variant="h1" sx={{ mb: 4 }}>
                    お知らせ
                </Typography>
                <Typography
                    component="pre"
                    variant="body1"
                    sx={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}
                >{`
2022年5月13日
HugoからGatsbyに移行しました

2022年2月18日
技術ブログ追加申請フォームを設置しました。
https://forms.gle/1dHB5SECd1Xz5Wg39

2021年11月15日
タグが小文字になってしまう問題を修正

2021年8月30日
タグ・カテゴリを整理

2021年8月24日
各ブログ画像はog:imageを使うように修正

2021年8月14日
サイトデザイン変更

2021年4月13日
サイト作成
                `}</Typography>
            </Container>
        </Layout>
    )
}

export default InformationPage
